<template>
  <!-- NAME[epic=销售] 销售汇总按客户 -->

  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline label-position="right">
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <sale-area-select-tree
          ref="saleAreaSelectTree"
          style="margin-right: 10px"
          @set-sale-select-id="selectArea"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="" prop="channel_id">
        <el-select
          v-model="form.channel_id"
          clearable
          style="width: 140px; margin-left: -10px"
          placeholder="渠道"
        >
          <el-option
            v-for="(i, idx) in channels"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="grade_id">
        <el-select
          v-model="form.grade_id"
          clearable
          style="width: 140px"
          placeholder="客户等级"
        >
          <el-option
            v-for="(i, idx) in clientLevelSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click.stop="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click.stop="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlePrint">打印</el-button>
      </el-form-item>
      <div class="more">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="df">
                <div class="df-a">更多筛选条件</div>
                <div class="df-b">
                  <!--                  <el-button
                    type="primary"
                    @click.stop="
                      () => {
                        form.pageSize = 10
                        form.pageNo = 1
                        fetchData()
                      }
                    "
                  >
                    查询
                  </el-button>
                  <el-button type="warning" @click.stop="clearForm">
                    清空
                  </el-button>
                  <el-button>导出</el-button>
                  <el-button>打印</el-button>-->
                </div>
              </div>
            </template>
            <el-form-item label="" prop="">
              <class-select
                ref="classSelect"
                @class-select-change="classChange"
              ></class-select>
            </el-form-item>
            <el-form-item label="" prop="">
              <brand-select
                ref="brandSelect"
                :duoxuan="true"
                style="width: 160px"
                @brand-select-change="brandChange"
              ></brand-select>
            </el-form-item>
            <el-form-item label="" prop="">
              <goods-search
                ref="goodsSearch"
                :is-table="false"
                @select-goods-all="goodsSelect"
              ></goods-search>
            </el-form-item>
            <el-form-item label="" prop="staff_id">
              <el-select
                v-model="form.staff_id"
                clearable
                style="width: 140px"
                placeholder="业务员"
              >
                <el-option
                  v-for="(i, idx) in staffList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="driver_id">
              <el-select
                v-model="form.driver_id"
                clearable
                placeholder="司机"
                style="width: 140px"
              >
                <el-option
                  v-for="(i, idx) in shrList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="bill_type">
              <el-select
                v-model="form.bill_type"
                clearable
                style="width: 140px"
                placeholder="来源"
              >
                <el-option
                  v-for="(i, idx) in lyList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="pay_status">
              <el-select
                v-model="form.pay_status"
                clearable
                style="width: 140px"
                placeholder="是否结清"
              >
                <el-option
                  v-for="(i, idx) in jqList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="goods_group">
              <el-select
                v-model="form.goods_group"
                :popper-class="'select-idx'"
                placeholder="商品分组"
                style="width: 130px"
                clearable
              >
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="
              item.label == '销售数量' ||
              item.label == '退货数量' ||
              item.label == '赠送数量'
            "
          >
            <span @click="handlerinfo(row)">
              {{ row[item.prop] }}
            </span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="LKH"
      :data_id="1"
      :type="1"
      :summary-params="form"
    ></ruilang-display-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="1600px"
      center
    >
      <el-row :gutter="10">
        <el-card v-if="datatable.sale">
          <el-table
            :data="datatable.sale.data_list"
            border
            show-summary
            :summary-method="summarySale"
          >
            <el-table-column prop="bill_code" label="销售单号" align="center">
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_type_text"
              label="类型"
              align="center"
            />
            <el-table-column prop="quantity" label="数量" align="center" />
            <el-table-column prop="unit_name" label="单位" align="center" />
            <el-table-column prop="goods_price" label="售价" align="center" />
            <el-table-column prop="goods_money" label="金额" align="center" />
            <el-table-column prop="staff_name" label="业务员" align="center" />
            <el-table-column prop="create_at" label="下单时间" align="center" />
          </el-table>
        </el-card>
        <el-card v-if="datatable.back">
          <el-table
            :data="datatable.back.data_list"
            border
            show-summary
            :summary-method="summaryBack"
          >
            <el-table-column prop="bill_code" label="退货单号" align="center">
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            >
            <el-table-column
              prop="goods_type_text"
              label="类型"
              align="center"
            />
            <el-table-column prop="quantity" label="数量" align="center" />
            <el-table-column prop="unit_name" label="单位" align="center" />
            <el-table-column prop="goods_price" label="退货价" align="center" />
            <el-table-column prop="goods_money" label="金额" align="center" />
            <el-table-column prop="staff_name" label="业务员" align="center" />
            <el-table-column prop="create_at" label="下单时间" align="center" />
          </el-table>
        </el-card>
        <el-card v-if="datatable.give">
          <el-table
            :data="datatable.give.data_list"
            border
            show-summary
            :summary-method="summaryGive"
          >
            <el-table-column prop="bill_code" label="赠送单号" align="center">
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_type_text"
              label="类型"
              align="center"
            />
            <el-table-column prop="quantity" label="数量" align="center" />
            <el-table-column prop="unit_name" label="单位" align="center" />
            <el-table-column prop="staff_name" label="业务员" align="center" />
            <el-table-column prop="create_at" label="下单时间" align="center" />
          </el-table>
        </el-card>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="exportExcel">导出</el-button>
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <check-order ref="checkOrder"></check-order>
    <check ref="checkXS" />
  </div>
</template>
<script>
  import _ from 'lodash'

  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { driverList } from '@/api/advanceOrder'
  import { depotList } from '@/api/depotManagement'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { clientCustChannel } from '@/api/ClientDetail'
  import { saleClientList } from '@/api/saleData'
  import { getClientLevelList, getStaffList } from '@/api/setPrice'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import { downloadFile, postAction } from '@/api/Employee'
  import { goodsDetailList } from '@/api/selectList'
  import CheckOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import check from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import {day_n} from "@/utils/Time";

  export default {
    name: 'SummaryClient',
    components: {
      CheckOrder,
      ClassSelect,
      BrandSelect,
      SaleAreaSelectTree,
      GoodsSearch,
      ClientSearch,
      RuilangDisplayDialog,
      check,
    },
    data() {
      return {
        title: '',
        dialogVisible: false,
        exceldata: {},
        datatable: {
          give: {
            data_list: [],
            data_sum: {},
          },
          back: {
            data_list: [],
            data_sum: {},
          },
          sale: {
            data_list: [],
            data_sum: {},
          },
        },
        totalAll: {},
        timeTypeSelect: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
        ],
        activeNames: ['0'],
        loading: false,
        time: [],
        channels: [],
        staffList: [],
        clientLevelSelect: [],
        shrList: [],
        lyList: [
          //1、车销 2、访销 3、网销 4、仓库直销
          {
            id: 1,
            name: '车销',
          },
          {
            id: 2,
            name: '访销',
          },
          {
            id: 3,
            name: '网销',
          },
          {
            id: 4,
            name: '仓库直销',
          },
        ],
        jqList: [
          // 0未结清 1已结清 2坏账
          {
            id: 0,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],

        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: [
          '客户名称',
          '商品名称',
          '规格',
          '成本价',
          '销售数量',
          '退货数量',
          '赠送数量',
          '销售金额',
          '退货金额',
          '净销售数量',
          '净销售金额',
          '毛利',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },

          {
            order: 2.1,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 2.2,
            label: '成本价',
            prop: 'base_price',
            width: '',
          },
          {
            order: 3,
            label: '销售数量',
            prop: 'sale_count',
            width: '',
          },
          {
            order: 4,
            label: '退货数量',
            prop: 'back_count',
            width: '',
          },
          {
            order: 5,
            label: '赠送数量',
            prop: 'give_count',
            width: '',
          },
          {
            order: 6,
            label: '销售金额',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 7,
            label: '退货金额',
            prop: 'back_money',
            width: '',
          },
          {
            order: 8,
            label: '净销售数量',
            prop: 'rate_count',
            width: '',
          },
          {
            order: 9,
            label: '净销售金额',
            prop: 'rate_money',
            width: '',
          },
          {
            order: 10,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '',
          },
        ],
        form: {
          time_type: 'arrive_at',

          start_time: '', //开始时间
          end_time: '', //结束时间
          class_id: '', //品类id
          brand_id: '', //品牌id
          staff_id: '', //业务员id
          driver_id: '', //送货员id
          channel_id: '', //渠道id
          goods_id: '', //商品id
          cust_id: '', //客户id
          area_id: '', //区域id
          grade_id: '', //等级id
          bill_type: '', //单据类型1、车销 2、访销 3、网销 4、仓库直销
          pay_status: '', //结算结果 0未结清 1已结清 2坏账
          pageNo: 1, //页数
          pageSize: 10, //条数
          order: '', //排序方法desc降序asc升序
          sort: '', //排序类型
        },
        url: {
          Export: '/saleAdmin/sale-form/export-customer',
        },
        groupList: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.time = day_n(90)
      this.form.start_time = this.time[0]
      this.form.end_time = this.time[1]
      this.getSelect()
      this.fetchData()
    },
    mounted() {
      goodsDetailList().then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.groupList = res.data.goods_group
        }
      })
    },
    activated() {
      this.fetchData()
    },
    methods: {
      checkOrder(row) {
        if (/XS/.test(row.bill_code)) {
          console.log('row', row)
          this.$refs['checkXS'].isshowDialog = true
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkXS'].id = row.order_id
        } else if (/XD/.test(row.bill_code)) {
          this.$refs['checkOrder'].showDialog()
          let text = ''
          if (row.check_status == 0) {
            text = row.bill_status_text
          } else {
            text = row.check_status_text
          }
          // this.$refs['checkOrder'].orderStatus2 = text
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = row.order_id
        }
        console.log(row)
      },
      handlerinfo(row) {
        const data = JSON.parse(JSON.stringify(this.form))
        data.cust_id = row.cust_id
        data.goods_id = row.goods_id
        this.exceldata = data
        postAction('/saleAdmin/sale-form/customer-detail', data).then((res) => {
          this.dialogVisible = true
          this.datatable = res.data.data
          this.title =
            res.data.info.cust_name +
            '(' +
            res.data.info.goods_name +
            res.data.info.goods_spec +
            ')'
        })
      },
      summaryBack() {
        let sum = []
        sum[0] = '合计'
        sum[2] = this.datatable.back.data_sum.quantity
        sum[5] = this.datatable.back.data_sum.goods_money
        return sum
      },
      summarySale() {
        let sum = []
        sum[0] = '合计'
        sum[2] = this.datatable.sale.data_sum.quantity
        sum[5] = this.datatable.sale.data_sum.goods_money
        return sum
      },
      summaryGive() {
        let sum = []
        sum[0] = '合计'
        sum[2] = this.datatable.sale.data_sum.quantity
        return sum
      },
      exportExcel() {
        downloadFile(
          '/superAdmin/dealer-real/index-export',
          this.title + '.xlsx',
          this.exceldata
        )
      },
      handleExport() {
        // downloadFile(this.url.Export, '销售明细(按客户).xlsx', this.form)
        postAction('/baseAdmin/export/add-export', {
          type: 6,
          ex_data: JSON.stringify(this.form),
        }).then((r) => {
          this.$message.success('导出中,请查看导出消息列表')
        })
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await saleClientList(this.form)
        this.total = totalCount
        this.list = data.list
        this.totalAll = data.total_data
        this.loading = false
      },
      selectArea(val) {
        this.form.area_id = val
      },
      classChange(val) {
        this.form.class_id = val
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      getSelect() {
        // 仓库下拉
        depotList().then((res) => {
          this.depotSelect = res.data
        })
        // 渠道下拉
        clientCustChannel().then((d) => {
          this.channels = d.data
        })
        // 客户等级下拉
        getClientLevelList().then((res) => {
          this.clientLevelSelect = res.data
        })
        // 送货人下拉
        driverList().then((res) => {
          this.shrList = res.data
        })
        // 获取业务员下拉
        getStaffList().then((res) => {
          if (res.code == 200) this.staffList = res.data
        })
      },
      clearForm() {
        this.time = []
        this.$refs.form.resetFields()
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.classSelect.resetForm()
        this.$refs.saleAreaSelectTree.resetForm()
        this.form.goods_id = ''
        this.form.cust_id = ''
        this.form.area_id = ''
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        let sums = []
        let a = this.totalAll
        sums[0] = '合计' //\n总计
        sums[5] = a.sale_count
        sums[8] = a.sale_money
        sums[6] = a.back_count
        sums[9] = a.back_money
        sums[11] = a.rate_money
        sums[10] = a.rate_count
        sums[7] = a.give_count
        sums[12] = a.gross_profit_amount

        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '合计'
        //   }
        // })
        console.log('sss', sums)
        return sums
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
